import React, { createRef, useEffect, useRef, useState } from 'react';
import { Icon, Input, Label, List, Switch } from 'ui';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { siteKey } from 'config/config';
import { Flex, Form } from 'antd';
import { SearchInspireWrapper, StyledListContainer, StyledSearchContainer } from './styles';
import { useDebounce } from 'utils/useDebounce';
import UserInstitutionSelect from 'components/Selects/UserInstitutionSelect';
import { useGeoProductsFilterContext } from 'contexts/GeoProductsFilterContext';

interface GeoProductListProps {
  setSelectedGeoProduct: (id: number) => void;
  setSelectedTitle: (title: string) => void;
}

const GeoProductList = ({ setSelectedGeoProduct, setSelectedTitle }: GeoProductListProps) => {
  const [showCaptcha, setShowCaptcha] = useState<boolean>();
  const [recaptchaKey, setRecaptchaKey] = useState<string | null>();
  const [visible, setVisible] = useState<boolean>(false);

  const { filterParams, setFilters, setSearchValues, form } = useGeoProductsFilterContext();
  const debouncedDataFilter = useDebounce(filterParams, 1000);
  const ref = useRef<HTMLDivElement>(null);
  const recaptchaRef = createRef<ReCAPTCHA>();
  const intl = useIntl();

  let [searchParams] = useSearchParams();

  useEffect(() => {
    const searchString = searchParams.get('search');
    const isOpen = searchParams.get('geoproduct') === 'open';

    if (isOpen && !!searchString) {
      form.setFieldValue('search', searchString);
      form.submit();
    }
  }, [window.location.search]);

  const renderSearchToggle = (label: string, arrowDirection: string) => (
    <Flex align="center" gap="small">
      <Icon icon={`angle-${arrowDirection}`} faBase="far" />{' '}
      <Label clickable label={intl.formatMessage({ id: label })} />
    </Flex>
  );

  const renderSearch = () => (
    <Form form={form} onFinish={({ search = '' }) => setSearchValues({ search })}>
      <Input
        name="search"
        placeholder={intl.formatMessage({ id: 'geoproducts.search_geoproduct' })}
        onChange={({ target: { value: search } }) => setSearchValues({ search })}
      />
      <StyledSearchContainer>
        <div className={`${!visible && 'hide-container'}`}>
          <UserInstitutionSelect
            name="organization"
            mode="multiple"
            type="WITH_PUBLIC_PRODUCTS"
            placeholder={intl.formatMessage({ id: 'planned_documents.search_by_organization' })}
            label="NONE"
            onChange={(organization: number[]) => setFilters({ organization })}
          />
          <SearchInspireWrapper>
            <Switch
              name="inspire"
              label={intl.formatMessage({ id: 'geoproducts.search_inspire' })}
              className="horizontal"
              onChange={(inspire: boolean) => setFilters({ inspire })}
            />
          </SearchInspireWrapper>
        </div>
        <Flex justify="space-between">
          <Flex onClick={() => setVisible((old) => !old)}>
            {!visible
              ? renderSearchToggle('planned_documents.additional_search', 'right')
              : renderSearchToggle('planned_documents.simple_search', 'down')}
          </Flex>
        </Flex>
        {showCaptcha && (
          <ReCAPTCHA
            className="recaptcha"
            ref={recaptchaRef}
            sitekey={siteKey}
            onChange={(value) => setRecaptchaKey(value)}
          />
        )}
      </StyledSearchContainer>
    </Form>
  );

  return (
    <>
      {renderSearch()}
      <StyledListContainer ref={ref}>
        <List
          url="api/v1/public/geoproducts"
          useClientHeight={{
            ref,
            enable: true,
          }}
          hoverItem={false}
          onSelect={(id: number) => setSelectedGeoProduct(id)}
          selectedTitle={(title: string) => setSelectedTitle(title)}
          filterParams={debouncedDataFilter}
          properties={{ title: 'name', id: 'id' }}
          captcha={{
            captchaKeyIsSet: !!recaptchaKey,
            captchaEnabled: showCaptcha,
            setCaptchaEnabled: setShowCaptcha,
          }}
          pageSize={20}
        />
      </StyledListContainer>
    </>
  );
};

export default GeoProductList;
