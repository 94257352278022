export enum COLORS {
  gray01 = '#FFFFFF',
  gray02 = '#F9F9FA',
  gray03 = '#D0D5DD',
  gray04 = '#EFEFF0',
  gray06 = '#475467',
  gray07 = '#0D283F',
  gray12 = '#0C0B0B',
  grayInactive = '#616C82',

  black = '#000000',

  grayLight = '#E1E1E1',
  grayDark = '#373337',

  brand01 = '#32353A',
  brand02 = '#518B33',
  brand02Light2 = 'rgba(81, 139, 51, 0.06)',
  brand03 = '#CFDEE0',

  brand02Light = 'rgba(81, 139, 51, 0.06)',
  brand02Dark = '#0000FF',

  errors = '#F6DCDA',
  errorsLight = '#F6E5E3',
  warning = '#EBE0C9',
  transparent = 'transparent',

  textColor01 = gray07,
  textColor02 = gray06,
  textColor03 = gray01,
  textColor04 = brand02,
  textColor05 = black, //on theme change stay dark
  textColor06 = textColor01,
  textColor07 = gray01, //on theme change stay white
  textColor08 = brand02,

  button01 = brand02,
  button02 = gray01,

  switchColor01 = '#518B33',
  switchColor02 = '#3EBD60',
  switchColor03 = '#E5E5E6',

  boxShadow = '#FAE2D8',

  formItemsBoxShadow = '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(81, 139, 51, 0.06)',
  formItemsBoxShadowDefault = '0px 1px 2px rgba(16, 24, 40, 0.05)',

  iconColor01 = gray06,
  iconColor02 = brand02,
  iconColor03 = gray01,
  iconColor04 = gray01,
  iconColor05 = brand02,
  iconColor06 = gray04,

  tableHeaderColor = gray01,

  disabledText = 'rgba(13, 40, 63, 0.5)',
  disabledBorder = '#D0D5DD',
  disabledBackground = '#F9FAFB',

  placeholder = '#A9A9AC',

  rowSelectBackground = '#e6f4ff',

  portalBackground = gray02,
  componentsBackground = gray01,

  border = '#E6E7EE',
  border2 = '#D0D5DD',
  border3 = '#EAECF0',
  border4 = brand02,
  border5 = '#AC5C36',
  customBorder = 'transparent',

  alert01 = '#f6ffed',
  alert02 = '#e6f7ff',
  alert03 = '#fffbe6',
  alert04 = '#fff2f0',

  alertBorder01 = '#b7eb8f',
  alertBorder02 = '#91d5ff',
  alertBorder03 = '#ffe58f',
  alertBorder04 = '#ffccc7',

  alertIcon01 = '#52c41a',
  alertIcon02 = '#1890ff',
  alertIcon03 = '#faad14',
  alertIcon04 = '#ff4d4f',

  tag01 = brand02,
  tag02 = '#f5222d',
  tag03 = '#d46b08',

  tagBackground01 = '#F5F8F3',
  tagBackground02 = '#fff1f0',
  tagBackground03 = '#fff7e6',

  tagBorder01 = 'transparent',
  tagBorder02 = '#ffa39e',
  tagBorder03 = '#ffd591',

  yellow = '#FAD47F',
  white = '#FFFFFF',

  lightYellow = '#f8dda2',

  rowGreen = '#008400',
  rowRed = '#f80100',

  orange01 = '#D97706',
  orange01Light = 'rgba(217, 119, 6, 0.06)',

  red01 = '#DC2626',
  red01Light = 'rgba(220, 38, 38, 0.06)',

  scroll = 'rgba(13, 40, 63, 0.04)',
  solitude = '#f2f4f7',
  paleSky = '#667085',
}
