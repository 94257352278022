import styled from 'styled-components/macro';
import { Label } from 'ui/label/Label';

export const StyledActions = styled.div`
  font-size: ${({ theme }) => theme.p3Size};
  padding: 0 0 16px;

  &:after {
    content: ' ';
    width: calc(100% + 52px);
    border-bottom: 1px solid ${({ theme }) => theme.border};
    display: block;
    margin-left: -26px;
  }

  .actions {
    margin: 0 0 16px;
  }

  .ant-tag {
    margin: initial;
    font-size: ${({ theme }) => theme.p3Size};
  }

  .status-version {
    font-size: ${({ theme }) => theme.p3Size};

    .portal-icon {
      color: ${({ theme }) => theme.iconColor07};
    }
  }

  .icons > * {
    padding: 0 8px;
    border-right: 1px solid ${({ theme }) => theme.border};
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.brand02};
    }

    &:last-child {
      border-right: none;
      padding-right: 0;
    }
  }
`;

export const StyledCard = styled.div`
  .card-btn {
    width: fit-content;
  }

  .date {
    color: ${({ theme }) => theme.textColor09};

    > * {
      margin: 0 0 2px;
    }
  }
`;

export const Section = styled.div`
  padding: 0 0 16px;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  margin: 0 0 16px;

  .section-title {
    font-weight: ${({ theme }) => theme.fontWeightBold};
    font-size: ${({ theme }) => theme.p1Size} !important;
  }

  .ant-tree-switcher_close .anticon {
    color: ${({ theme }) => theme.iconColor07};
    margin: 2px 0 0;
  }

  .ant-tree-switcher {
    display: flex;
    width: initial;
    align-items: flex-start;
  }

  .ant-tree-node-content-wrapper {
    padding: initial;

    &-close {
      color: ${({ theme }) => theme.brand02};
    }
  }

  .ant-tree {
    .ant-tree-treenode {
      overflow: initial;
    }

    .ant-tree-node-content-wrapper {
      line-height: initial;
      min-height: initial;
    }
  }

  .event-content-wrapper {
    font-size: ${({ theme }) => theme.p3Size};
    padding: 16px calc(${({ theme }) => theme.lineHeight9} + 26px);
    margin: 0 -26px 0 calc((${({ theme }) => theme.lineHeight9} + 26px) * -1);

    &,
    &:hover {
      background-color: ${({ theme }) => theme.dropdownFieldBackground};
    }
  }

  .ant-tree-list-holder-inner {
    gap: 5px;

    & > * {
      display: grid;
      grid-template-columns: ${({ theme }) => theme.lineHeight9} auto;
    }
  }

  &.participation-section {
    .event-content-wrapper {
      padding: 16px 26px;
    }

    .submit-proposal-wrapper {
      margin: 16px 0 0;
    }
  }

  &.decision-section {
    .tree-group-wrapper {
      .date {
        margin: 0 0 0 calc(${({ theme }) => theme.lineHeight9} + 2px);
        font-size: ${({ theme }) => theme.p3Size} !important;
        color: ${({ theme }) => theme.textColor09};
      }
    }

    .show-more-btn {
      .text {
        font-weight: ${({ theme }) => theme.fontWeightRegular};
      }

      button:focus {
        color: ${({ theme }) => theme.textColor01};
      }

      button:hover {
        background-color: initial;
      }

      i {
        padding: 2px 0 0;
        height: 100%;
        font-size: ${({ theme }) => theme.iconSize3};

        &:before {
          margin: auto 0;
        }
      }
    }
  }

  &.layers-section {
    margin: initial;
    border: initial;

    .ant-tree-treenode {
      padding: initial;
      align-items: center;
      line-height: initial;
      font-size: ${({ theme }) => theme.p3Size};
    }

    .ant-tree-checkbox {
      margin: 0 4px;
      align-self: initial;

      .ant-tree-checkbox-inner {
        width: ${({ theme }) => theme.iconSize2} !important;
        height: ${({ theme }) => theme.iconSize2} !important;

        &:after {
          width: calc(${({ theme }) => theme.checkmarkWidth} - 2px) !important;
          height: calc(${({ theme }) => theme.checkmarkHeight} - 2px) !important;
        }
      }

      &-checked .ant-tree-checkbox-inner {
        background-color: ${({ theme }) => theme.brand02};

        &:after {
          border-color: ${({ theme }) => theme.textColor03};
        }
      }
    }

    .ant-tree-list-holder-inner {
      gap: 5px;

      & > * {
        display: flex;
      }
    }

    .ant-tree-switcher {
      align-items: center;
    }

    .ant-tree-switcher-icon {
      font-size: ${({ theme }) => theme.p3Size} !important;
      margin: 1px 0 0 !important;
    }

    .ant-tree-node-content-wrapper {
      padding: initial;
    }
  }
`;

export const DecisionAndMaterialListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .plannedDocuments__decision {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .plannedDocuments__decision--time {
    color: ${({ theme }) => theme.gray06};
    font-size: ${({ theme }) => theme.p2Size};
    line-height: ${({ theme }) => theme.lineHeight9};
  }

  .additional_documents {
    margin-left: 12px;
  }
`;

export const StyledFileVersionWrapper = styled.div`
  color: ${({ theme }) => theme.placeholder};

  i {
    color: ${({ theme }) => theme.placeholder};
  }
`;

export const StyledApplyTextWrapper = styled.p`
  font-size: ${({ theme }) => theme.p3Size};
  margin: 0;
`;

export const StyledNotificationStep = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .img-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;

    img {
      max-width: 70%;
      height: auto;
      object-fit: contain;
    }
  }
`;

export const StyledApplicationNameWrapper = styled.div`
  .ant-form-item-label {
    padding-bottom: 8px !important;

    label {
      line-height: ${({ theme }) => theme.lineHeight8} !important;
      font-size: ${({ theme }) => theme.p2Size} !important;
      font-weight: 600 !important;
      color: ${({ theme }) => theme.gray07} !important;
    }
  }
`;

export const StyledOther = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.p3Size};
`;

export const StyledSearchContainer = styled.div`
  .hide-container {
    display: none;
  }

  .ant-select-selection-overflow-item .ant-select-selection-item .ant-select-selection-item-content {
    max-width: 250px;
  }

  .last_status_change > *,
  .fa-arrows-from-line {
    color: ${({ theme }) => theme.textColor04};
  }

  .compress-icon {
    margin: -1px 15px 0 auto;
  }

  .recaptcha {
    margin: 8px 0 0;
  }
`;

export const StyledPopover = styled.div`
  padding: 8px 16px;

  .entry {
    cursor: pointer;
  }

  &.versions-list.detailed-planned-documents {
    font-size: ${({ theme }) => theme.p3Size};
  }
`;

export const StyledTreeItemsWrapper = styled.div`
  font-size: ${({ theme }) => theme.p3Size};

  .tree-wrapper {
    margin: 12px 0 0;

    .ant-tree-treenode {
      align-items: center;
      padding: initial;
      gap: 2px;
    }

    .ant-tree-list-holder-inner {
      gap: 5px;
    }
  }

  .ant-tree-indent {
    display: none;
  }

  .ant-tree-node-content-wrapper {
    padding-left: 0px;
  }

  .ant-tree-treenode {
    overflow: hidden;
  }

  .ant-tree-switcher-noop {
    visibility: hidden;
  }

  .ant-tree-title {
    font-size: ${({ theme }) => theme.p3Size} !important;
  }

  .ant-tree-switcher {
    width: auto !important;
  }

  .ant-tree-indent-unit {
    width: 16px !important;
  }

  .small-text-wrapper-title {
    font-weight: ${({ theme }) => theme.fontWeightBold};
    font-size: ${({ theme }) => theme.p3Size};
    margin: 0 0 4px;
    color: ${({ theme }) => theme.textColor01};
  }

  .reception-wrapper {
    white-space: pre-line;
  }

  .small-text-wrapper {
    font-weight: ${({ theme }) => theme.fontWeightRegular};
    font-size: ${({ theme }) => theme.p3Size};
    line-height: ${({ theme }) => theme.lineHeight8};
    border-bottom: 1px solid ${({ theme }) => theme.border};
    padding-bottom: 10px;
    margin: 0 0 10px;
    color: ${({ theme }) => theme.textColor09};

    &:last-child {
      padding-bottom: initial;
      margin: initial;
      border: initial;
    }
  }

  .subtitle-text {
    font-weight: bold;
  }

  .title-text {
    font-size: ${({ theme }) => theme.p3Size};
    line-height: ${({ theme }) => theme.lineHeight8};
  }

  .file-link {
    color: ${({ theme }) => theme.brand02};

    &:hover {
      color: ${({ theme }) => theme.brand02};
    }
  }
`;

export const StyledListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  &.planned-documents {
    padding: initial;

    .rc-virtual-list-holder-inner {
      padding: initial;

      & > * {
        border-radius: initial;
        margin: initial;
        padding: 16px 27px;
        border: initial;
        border-bottom: 1px solid ${({ theme }) => theme.border3};

        &:hover {
          cursor: pointer;

          .title,
          .shortTitle {
            color: ${({ theme }) => theme.textColor04};
          }
        }

        .box {
          .ant-tag {
            margin: 8px 0 0;
            font-size: ${({ theme }) => theme.p3Size};
          }

          &.compact {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;

            .ant-tag {
              margin: initial;
            }
          }

          .title,
          .shortTitle {
            margin: initial;
            font-weight: ${({ theme }) => theme.fontWeightBold};
          }
        }
      }
    }

    &.compact .rc-virtual-list-holder-inner > * {
      padding: 4px;
    }
  }
}
`;

export const StyledImage = styled.img`
  object-fit: contain;
  width: 330px;
`;

export const TreeWrapper = styled.div`
  color: ${({ theme }) => theme.brand02};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  font-size: ${({ theme }) => theme.p2Size};

  .ant-tree-indent {
    display: none;
  }

  .ant-tree .ant-tree-node-content-wrapper {
    line-height: initial;
    min-height: initial;
    padding: initial;
  }

  .ant-tree-treenode {
    align-items: center;
    padding: initial;
    gap: 2px;
  }
`;

export const TooltipWrapper = styled.div`
  .ant-tooltip {
    max-width: 300px;
  }
`;

export const ParsedLabel = styled(Label)`
  display: flex;
  flex-direction: column;

  & > * {
    margin: 0;
  }
`;

export const PopoverWrapper = styled.div`
  padding: 8px;
  font-weight: normal;
  font-size: ${({ theme }) => theme.p3Size};
  max-width: 250px;
  color: ${({ theme }) => theme.textColor01};
`;

export const StyledTooltipWrapper = styled.div`
  .tooltip-inner-div {
    width: 100%;

    .button {
      width: 100%;
    }
  }
`;

export const StyledATag = styled.a`
  color: ${({ theme }) => theme.black};

  &:hover {
    color: ${({ theme }) => theme.black};
  }
`;

export const StyledDescriptionWrapper = styled.div`
  padding-bottom: 16px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;

  .ant-tooltip-inner {
    max-width: 250px;
    text-align: center;
  }
`;

export const ExplanationWrapper = styled.div`
  color: ${({ theme }) => theme.gray06};
`;

export const StyledHref = styled.a<{
  isImportant: boolean;
}>`
  text-decoration: underline;
  color: ${({ theme, isImportant }) => (isImportant ? '#b2291f' : theme.textColor01)};

  &:hover {
    color: ${({ theme, isImportant }) => (isImportant ? '#b2291f' : theme.textColor01)};
  }
`;

export const CustomStyledLink = styled.a`
  color: ${({ theme }) => theme.brand02};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  font-size: ${({ theme }) => theme.p3Size};

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;

  &:hover {
    color: ${({ theme }) => theme.brand02};
  }
`;

export const CustomStyledChildrenLinkWrapper = styled.div`
  .styled-link {
    color: ${({ theme }) => theme.brand02};
    font-weight: ${({ theme }) => theme.fontWeightBold};
    line-height: ${({ theme }) => theme.lineHeight11};

    &:hover {
      color: ${({ theme }) => theme.brand02};
    }
  }
`;

export const SearchInspireWrapper = styled.div`
  max-width: 250px;

  .ant-form-item-row {
    align-items: center;
  }
`;

export const ApplyForNotificationSideBarWrapper = styled.div`
  .content {
    padding-top: 12px;

    label {
      line-height: ${({ theme }) => theme.lineHeight8};
    }
  }

  .sidebar-title {
    padding-bottom: 12px;
  }
`;

export const StyledRemoveButton = styled.p`
  .ant-btn-text {
    margin-left: -7px;
    padding: 4px 7px;
  }
`;
