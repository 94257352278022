import React, { type MutableRefObject } from 'react';
import { StyledSidebarDrawer } from './style';
import { Icon, Label } from 'ui';
import { useNotificationHeader } from '../../contexts/NotificationHeaderContext';
import { Flex } from 'antd';

export interface SidebarDrawerProps {
  children?: React.ReactNode;
  title: string;
  subtitle?: string | React.ReactNode;
  onClose?: () => void | Function;
  isOpen?: boolean;
  closeIcon?: React.ReactNode;
  className?: string;
}

export const SidebarDrawer = ({
  children,
  title,
  subtitle,
  onClose,
  isOpen,
  closeIcon,
  className,
}: SidebarDrawerProps) => {
  const { height } = useNotificationHeader();

  const renderCloseIcon = () => {
    return (
      <Flex justify="center" align="center" className="close-icon-wrapper clickable" onClick={onClose}>
        <Icon className="close-icon" icon="xmark" faBase="fa-solid" />
      </Flex>
    );
  };

  return (
    <StyledSidebarDrawer
      notificationHeight={height}
      trigger={null}
      collapsible
      collapsed={!isOpen}
      collapsedWidth={0}
      width={380}
      className={className}
    >
      <div className="sidebar-title">
        <div>
          <Label title label={title} bold />
          {subtitle && (typeof subtitle !== 'string' ? subtitle : <Label className="mt-1_5" label={subtitle} />)}
        </div>
        {closeIcon || renderCloseIcon()}
      </div>
      <div className="content">{children}</div>
    </StyledSidebarDrawer>
  );
};
