import React from 'react';
import { UploadFile as AntdUploadFile, UploadProps } from 'antd';
import { StyledUpload } from './style';
import { Form } from 'antd';
import { routes } from 'config/config';
import useJwt from 'utils/useJwt';
import type { NamePath, Rule } from 'rc-field-form/lib/interface';
import { UploadChangeParam as AntdUploadChangeParam } from 'antd/es/upload/interface';
import { Button } from '../button';
import { useIntl } from 'react-intl';
import { Icon } from '../icon';
import ToastMessage from 'utils/toastMessage';
import useFormValidation from 'utils/useFormValidation';
import { Validations } from 'interfaces/shared';

export interface UploadFile extends AntdUploadFile {
  id: number;
}

export interface UploadChangeParam extends AntdUploadChangeParam<UploadFile> {}

export interface ExtraUploadProps extends UploadProps, Validations {
  children?: React.ReactNode;
  name: NamePath;
  label?: React.ReactNode;
  rules?: Rule[];
}

export const Upload = ({
  multiple,
  accept,
  children,
  onChange,
  onDownload,
  onPreview,
  onRemove,
  customRequest,
  showUploadList,
  maxCount,
  name,
  disabled,
  label,
  data,
  validations,
  rules = [],
}: ExtraUploadProps) => {
  const { getHeader } = useJwt();
  const intl = useIntl();
  const { formValidations } = useFormValidation();

  const requestData = {
    bucket: 'default',
    ...data,
  };

  const getErrorMessages = ({ response = {} }: UploadFile) => {
    const { error = '{}' } = response;
    const { file: fileErrors = [] } = JSON.parse(error);

    return fileErrors.map((id: string) => intl.formatMessage({ id }));
  };

  const handleDownload = (file: UploadFile) => {
    if (onDownload) {
      onDownload(file);

      return;
    }

    const a = document.createElement('a');

    a.href = getFileData(file, 'url');
    a.download = getFileData(file, 'name');

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const formatFiles = ({ fileList }: UploadChangeParam) =>
    fileList.map((file: UploadFile) => ({
      ...file,
      url: getFileData(file, 'url'),
      id: getFileData(file, 'id'),
      error: new Error(getErrorMessages(file).join('. ')),
    }));

  const getFileData = (file: UploadFile, field: keyof UploadFile) => file[field] || file.response?.[field] || '';

  const getShowUploadList = () =>
    showUploadList || {
      showRemoveIcon: true,
      showDownloadIcon: true,
      removeIcon: <Icon icon="trash" />,
      downloadIcon: <Icon icon="download" />,
    };

  const onStatusChange = (info: AntdUploadChangeParam) => {
    const {
      file,
      fileList,
      file: { status },
    } = info;

    if (status === 'error') {
      getErrorMessages(file as UploadFile).map((error: string) => ToastMessage.error(error));
    }

    onChange && onChange(info);
  };

  const getRules = () => {
    const validFileRule = {
      transform: (files: UploadFile[]) => {
        const errorsMessages = files
          .map((file: UploadFile) => getErrorMessages(file))
          .flat()
          .filter(Boolean);
        const uniqueMessages = new Set(errorsMessages);

        return Array.from(uniqueMessages);
      },
      validator: (_: any, errors: string[]) => (errors.length ? Promise.reject(errors) : Promise.resolve()),
    };

    if (validations) {
      return [...formValidations(validations), validFileRule];
    }

    return [...rules, validFileRule];
  };

  return (
    <Form.Item label={label} name={name} getValueFromEvent={formatFiles} valuePropName="fileList" rules={getRules()}>
      <StyledUpload
        action={`${routes.api.baseUrl}/api/v1/storage/upload`}
        multiple={multiple}
        accept={accept}
        onChange={onStatusChange}
        onDownload={(file) => handleDownload(file as UploadFile)}
        onPreview={onPreview}
        onRemove={onRemove}
        customRequest={customRequest}
        showUploadList={getShowUploadList()}
        maxCount={maxCount}
        disabled={disabled}
        headers={{ Authorization: getHeader() }}
        data={requestData}
      >
        {children || <Button label={intl.formatMessage({ id: 'general.choose' })} />}
      </StyledUpload>
    </Form.Item>
  );
};
