import styled from 'styled-components/macro';

export const StyledReportInfo = styled.div`
  .report-filter-info {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.text1};
    font-size: ${({ theme }) => theme.p1Size};
  }
`;

export const StyledConfigModal = styled.div`
  label[for='type'] {
    margin: 0 0 0 10px;
  }
`;
