import styled, { css } from 'styled-components/macro';
import { COLORS, VARIABLES } from 'styles/globals';

const darkButtonText = css`
  color: ${({ theme }) => theme.textColor01};
  border: 2px solid ${({ theme }) => theme.textColor01};

  &:hover {
    color: ${({ theme }) => theme.textColor01} !important;
  }

  i,
  &.ant-btn-default:hover .portal-icon {
    color: ${({ theme }) => theme.textColor01} !important;
  }
`;

const lightButtonText = css`
  color: ${({ theme }) => theme.textColor03};
  border: 2px solid ${({ theme }) => theme.textColor03};

  &:hover {
    color: ${({ theme }) => theme.textColor03} !important;
  }

  i {
    color: ${({ theme }) => theme.textColor03};
  }
`;

export const StyledLayerSwitcher = styled.div<{ embedViewPort?: boolean }>`
  bottom: ${VARIABLES.mapButtonMargin};
  left: ${VARIABLES.mapButtonMargin};
  display: flex;
  align-items: center;
  background-color: transparent;
  gap: 6px;

  &.ol-control,
  button,
  .btn-wrapper {
    border-radius: ${VARIABLES.mapButtonBorderRadius} !important;
  }

  button {
    background-color: ${({embedViewPort, theme}) =>
      embedViewPort ? `${COLORS.gray03} !important` : `${theme.gray01}!important`};
    width: ${VARIABLES.mapLayerButtonSize} !important;
    height: ${VARIABLES.mapLayerButtonSize} !important;
    border: 1px solid ${({theme}) => theme.border};
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-size: ${VARIABLES.mapLayerButtonSize} ${VARIABLES.mapLayerButtonSize};
    background-color: ${({embedViewPort, theme}) =>
      embedViewPort ? `${COLORS.gray03} !important` : `${theme.gray01}!important`};

    i {
      margin: 0px !important;
    }

    &.osm {
      background-image: url('/img/map/osm.png');
      ${darkButtonText}
    }

    &.topo {
      background-image: url('/img/map/topo.png');
      ${lightButtonText}
    }

    &.orto {
      background-image: url('/img/map/orto.png');
      ${lightButtonText}
    }

    &.empty {
      background-image: url('/img/map/empty.png');
      ${darkButtonText}
    }

    &.more {
      color: ${({ theme }) => theme.textColor01} !important;
      border-color: ${({ theme }) => theme.brand02} !important;
    }

    &.options,
    &.options.ant-btn-default:hover {
      border: 1px solid ${({ theme }) => theme.gray03} !important;
    }
  }

  .btn-wrapper {
    border: 2px solid transparent;

     &.selected {
       border-color: ${({ theme }) => theme.brand02} !important;
     }

    .selected .options {
      border-color: ${({ theme }) => theme.white} !important;
    }
  }

  &.geo-product .empty {
    background-image: initial;
  }

   &.expanded {
    padding: 8px 8px;
    bottom: calc(${VARIABLES.mapButtonMargin} - 8px);
    left: calc(${VARIABLES.mapButtonMargin} - 8px);
    background-color: ${({embedViewPort, theme}) =>
      embedViewPort ? `${COLORS.gray03} !important` : `${theme.gray01}!important`};
   }

  .layer-button:hover {
    background-color: ${({ theme }) => theme.brand02} !important;
    color: ${({ theme }) => theme.textColor03} !important;
  }
`;

export const VerticalLine = styled.div`
  border-left: 1px solid ${({ theme }) => theme.gray03};
  width: 1px;
  height: ${VARIABLES.mapLayerButtonSize};
  margin: 0px 6px 0px 6px;
`;
