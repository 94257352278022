import styled from 'styled-components/macro';
import { Layout } from 'antd';
import { VARIABLES } from 'styles/globals';

const { Sider } = Layout;

export const StyledSidebarDrawer = styled(Sider)<{ notificationHeight?: number }>`
  background-color: ${({ theme }) => theme.componentsBackground};
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.textColor01};
  min-width: 380px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
  height: calc(100% + ${VARIABLES.footerHeight});
  overflow: auto;

  &.ant-layout-sider {
    background-color: ${({ theme }) => theme.componentsBackground};
  }

  &.ant-layout-sider-collapsed {
    .ant-layout-sider-children {
      display: none;
    }

    .sidebar-title {
      display: none;
    }

    .content {
      display: none;
    }
  }

  .sidebar-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 26px;
    background-color: ${({ theme }) => theme.portalBackground};
    gap: 5px;

    .tooltip,
    .close-icon-wrapper {
      margin-bottom: auto;
    }

    .portal-icon {
      cursor: pointer;
    }

    .close-icon {
      font-size: ${({ theme }) => theme.iconSize3};

      &-wrapper {
        min-width: ${({ theme }) => theme.iconSize0};
        min-height: ${({ theme }) => theme.iconSize0};
        border: 1px solid ${({ theme }) => theme.customBorder};
        border-radius: 50%;

        &:hover {
          background-color: ${({ theme }) => theme.iconColor06};
        }
      }
    }
  }

  .content {
    padding: 26px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: calc(100% - 84px);

    & > * {
      border-bottom: 1px solid ${({ theme }) => theme.border};
      padding-bottom: 20px;
      color: ${({ theme }) => theme.textColor01};
    }
  }

  &.planned-documents,
  &.detailed-planned-documents {
    .content {
      padding: 16px 27px 0;
    }

    .sidebar-title {
      padding: 24px 27px;

      .title {
        font-size: ${({ theme }) => theme.h4Size} !important;
      }
    }
  }

  &.planned-documents {
    .content {
      gap: initial;
      padding: initial;

      & > form:first-child {
        border: initial;
        padding: initial;
        margin: 16px 27px;
      }

      & > div:nth-child(2) {
        border: initial;

        &:after {
          content: '';
          width: 100%;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 80%);
          position: absolute;
          display: inline-block;
          height: 14px;
        }
      }
    }

    form .action-wrapper {
      margin: 16px 0 0;

      i {
        padding: 2px 0 0;
        display: flex;
        height: 100%;
        font-size: ${({ theme }) => theme.iconSize3};

        &:before {
          margin: auto 0;
        }
      }
    }
  }

  &.detailed-planned-documents {
    .version-popover {
      display: flex;
      align-items: center;

      b {
        font-weight: ${({ theme }) => theme.fontWeightBold};
      }
    }

    .content {
      padding: 16px 28px;

      & > * {
        padding: initial;
        border-bottom: initial;
      }
    }
  }
`;
