import { COLORS } from 'styles/globals';

export const defaultThemeColors = {
  gray01: COLORS.gray01,
  gray02: COLORS.gray02,
  gray03: COLORS.gray03,
  gray06: COLORS.gray06,
  gray07: COLORS.gray07,
  gray12: COLORS.gray12,
  grayLight: COLORS.grayLight,
  grayInactive: COLORS.grayInactive,

  brand01: COLORS.brand01,
  brand02: COLORS.brand02,
  brand03: COLORS.brand03,

  brand02Light: COLORS.brand02Light,

  errors: COLORS.errors,
  errorsLight: COLORS.errorsLight,
  warning: COLORS.warning,

  // alert01: COLORS.alert01,
  // alert02: COLORS.alert02,
  // alert03: COLORS.alert03,

  textColor01: COLORS.textColor01,
  textColor02: COLORS.textColor02,
  textColor03: COLORS.textColor03,
  textColor04: COLORS.textColor04,
  textColor05: COLORS.textColor05,
  textColor06: COLORS.textColor06,
  textColor07: COLORS.textColor07,
  textColor08: COLORS.textColor08,
  textColor09: COLORS.paleSky,

  button01: COLORS.button01,
  button02: COLORS.button02,

  switchColor01: COLORS.switchColor01,
  switchColor02: COLORS.switchColor02,
  switchColor03: COLORS.switchColor03,

  boxShadow: COLORS.boxShadow,

  placeholder: COLORS.placeholder,
  rowSelectBackground: COLORS.rowSelectBackground,
  checkedCheckboxBackground: COLORS.brand02,

  iconColor01: COLORS.iconColor01,
  iconColor02: COLORS.iconColor02,
  iconColor03: COLORS.iconColor03,
  iconColor04: COLORS.iconColor04,
  iconColor05: COLORS.iconColor05,
  iconColor06: COLORS.iconColor06,
  iconColor07: COLORS.paleSky,

  tableHeaderColor: COLORS.tableHeaderColor,

  disabledText: COLORS.disabledText,
  disabledBorder: COLORS.disabledBorder,
  disabledBackground: COLORS.disabledBackground,

  portalBackground: COLORS.portalBackground,
  componentsBackground: COLORS.componentsBackground,

  border: COLORS.border,
  border2: COLORS.border2,
  border3: COLORS.border3,
  border4: COLORS.border4,
  border5: COLORS.border5,
  customBorder: COLORS.customBorder,

  alert01: COLORS.alert01,
  alert02: COLORS.alert02,
  alert03: COLORS.alert03,
  alert04: COLORS.alert04,

  alertBorder01: COLORS.alertBorder01,
  alertBorder02: COLORS.alertBorder02,
  alertBorder03: COLORS.alertBorder03,
  alertBorder04: COLORS.alertBorder04,

  alertIcon01: COLORS.alertIcon01,
  alertIcon02: COLORS.alertIcon02,
  alertIcon03: COLORS.alertIcon03,
  alertIcon04: COLORS.alertIcon04,

  tag01: COLORS.tag01,
  tag02: COLORS.tag02,
  tag03: COLORS.tag03,

  tagBackground01: COLORS.tagBackground01,
  tagBackground02: COLORS.tagBackground02,
  tagBackground03: COLORS.tagBackground03,

  tagBorder01: COLORS.tagBorder01,
  tagBorder02: COLORS.tagBorder02,
  tagBorder03: COLORS.tagBorder03,

  yellow: COLORS.yellow,
  black: COLORS.black,
  white: COLORS.white,

  rowGreen: COLORS.rowGreen,
  rowRed: COLORS.rowRed,

  brand02Light2: COLORS.brand02Light2,
  formItemsBoxShadow: COLORS.formItemsBoxShadow,
  transparent: COLORS.transparent,
  formItemsBoxShadowDefault: COLORS.formItemsBoxShadowDefault,

  orange01: COLORS.orange01,
  orange01Light: COLORS.orange01Light,
  red01: COLORS.orange01Light,
  red01Light: COLORS.orange01Light,

  scroll: COLORS.scroll,

  solitude: COLORS.solitude,

  dropdownFieldBackground: COLORS.gray02,
  tableHoverBackground: COLORS.gray01,
  timePickerCellBg: COLORS.brand02,
  timePickerCellText: COLORS.textColor03,
  plannedDocsBg: COLORS.yellow,
};
