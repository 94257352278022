import React, { ReactNode, useState } from 'react';
import DefaultLayout from 'components/DefaultLayout';
import { Table, Button, Popover, Icon, Tag } from 'ui';
import { useIntl } from 'react-intl';
import { StyledPage, ButtonList } from 'styles/layout/table';
import { pages } from 'constants/pages';
import useQueryApiClient from 'utils/useQueryApiClient';
import dayjs from 'dayjs';
import { EmailNotification, EmailNotificationStatus } from './EmailNotificationsCreateEditPage';

const EmailNotificationListPage = () => {
  const [reload, setReload] = useState<number>(0);
  const [selectedKeys, setSelectedKeys] = useState<number[]>([]);

  const intl = useIntl();

  const getStatusData = (
    status: EmailNotificationStatus
  ): { title: ReactNode; date: (record: EmailNotification) => string } => {
    const defaultFormat = 'DD.MM.YYYY HH:mm';

    switch (status) {
      case 'SENT':
        return {
          title: <Tag label={intl.formatMessage({ id: 'email_notification.sent' })} color="green" />,
          date: ({ sentAt }: EmailNotification) => (sentAt ? dayjs(sentAt).format(defaultFormat) : ''),
        };
      case 'PLANNED':
        return {
          title: <Tag label={intl.formatMessage({ id: 'email_notification.planned' })} color="gray" />,
          date: ({ scheduledAt }: EmailNotification) => (scheduledAt ? dayjs(scheduledAt).format(defaultFormat) : ''),
        };
      case 'DRAFT':
      default:
        return {
          title: <Tag label={intl.formatMessage({ id: 'email_notification.draft' })} color="gray" />,
          date: ({ scheduledAt }: EmailNotification) => (scheduledAt ? dayjs(scheduledAt).format(defaultFormat) : ''),
        };
    }
  };

  const reloadItems = () => {
    setReload((old) => old + 1);
    setSelectedKeys([]);
  };

  const { appendData: appendDataDelete } = useQueryApiClient({
    request: {
      url: `api/v1/email-notifications`,
      method: 'DELETE',
    },
    onSuccess: reloadItems,
  });

  const { appendData: saveData } = useQueryApiClient({
    request: {
      url: 'api/v1/email-notifications/:id',
      method: 'PATCH',
    },
    onSuccess: reloadItems,
  });

  const sendEmail = ({ id }: EmailNotification) => {
    saveData({ status: 'PLANNED', scheduledAt: null }, { id });
  };

  const renderSendButton = (record: EmailNotification) => {
    if (record.status === 'SENT') {
      return (
        <div className="popover-item" onClick={() => sendEmail(record)}>
          {intl.formatMessage({ id: 'email_notification.send_again' })}
        </div>
      );
    }

    return (
      <div className="popover-item" onClick={() => sendEmail(record)}>
        {intl.formatMessage({ id: 'email_notification.send' })}
      </div>
    );
  };

  const columns = [
    {
      title: intl.formatMessage({ id: 'email_notification.subject' }),
      dataIndex: 'subject',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'email_notification.recipient_list' }),
      dataIndex: 'recipientLabel',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'email_notification.status' }),
      dataIndex: 'status',
      render: (status: EmailNotificationStatus) => getStatusData(status).title,
    },
    {
      title: intl.formatMessage({ id: 'email_notification.sent_at' }),
      render: (record: EmailNotification) => getStatusData(record.status).date(record),
    },
    {
      render: (record: EmailNotification) => (
        <Popover content={renderSendButton(record)} trigger="click" placement="bottom">
          <Icon faBase="fal" icon="ellipsis-v" />
        </Popover>
      ),
    },
  ];

  return (
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageContent>
        <StyledPage>
          <DefaultLayout.PageHeader
            breadcrumb={[
              {
                path: '/',
                name: intl.formatMessage({ id: 'general.administration' }),
              },
            ]}
            title={intl.formatMessage({ id: 'navigation.email_notifications' })}
          />
          <div className="theme-container">
            <Table
              url="/api/v1/email-notifications"
              columns={columns}
              enableSelectedRow
              linkProps={{ url: pages.emailNotification.edit.url }}
              reload={reload}
              setSelectedKeys={setSelectedKeys}
              tableActions={
                <ButtonList>
                  <Button
                    label={intl.formatMessage({ id: 'general.delete_chosen' })}
                    onClick={() => appendDataDelete({ ids: selectedKeys })}
                    disabled={!selectedKeys.length}
                  />
                  <Button
                    type="primary"
                    label={intl.formatMessage({ id: 'general.add_new' })}
                    href={pages.emailNotification.create.url}
                  />
                </ButtonList>
              }
            />
          </div>
        </StyledPage>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  );
};

export default EmailNotificationListPage;
