import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { Button, Icon, Label, Popover, Spinner, Tag, Tooltip } from 'ui';
import useQueryApiClient from 'utils/useQueryApiClient';
import {
  CustomStyledChildrenLinkWrapper,
  CustomStyledLink,
  ExplanationWrapper,
  PopoverWrapper,
  Section,
  StyledActions,
  StyledCard,
  StyledFileVersionWrapper,
  StyledHref,
  StyledPopover,
  StyledTreeItemsWrapper,
  TooltipWrapper,
  TreeWrapper,
} from './styles';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { listStatuses } from 'config/config';
import { TreeProps } from 'antd/es/tree';
import { ProposalSubmitContext } from 'contexts/ProposalSubmitContext';
import PlannedDocumentLayerSettings from 'components/Map/LayerSettings/PlannedDocumentLayerSettings';
import { useOpenedTypeDispatch } from 'contexts/OpenedTypeContext';
import LayerGroup from 'ol/layer/Group';
import { highlightFeaturesGeoJson } from 'utils/mapUtils';
import MapContext from 'contexts/MapContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePlannedDocumentProposal } from 'contexts/PlannedDocumentProposalContext';
import UnauthenticatedModal from 'components/Modals/UnauthenticatedModal';
import useJwt from 'utils/useJwt';
import { useUserState } from 'contexts/UserContext';
import { convertBytesToMegabytes } from 'utils/convertBytesToMegabytes';
import { DirectoryTree } from 'ui/directoryTree';
import toastMessage from 'utils/toastMessage';
import { Flex } from 'antd';
import { navigationAction } from 'constants/navigation';

interface DetailedPlannedDocumentProps {
  id: number;
  isHovered?: boolean;
  documentTitle: (documentTitle: string) => void;
  documentMunicipality: (documentMunicipality: string) => void;
  isZoomToPlannedDocument?: boolean;
}

interface DecisionAdditionalAttachementsType {
  FileUrl: string;
  OriginalDocumentId: number;
  FileAcceptance: string;
  FileSize: number;
  FileName: string;
  OriginalDocumentVersion: number;
}

interface DecisionAttachementsType {
  TapisId: number;
  FileUrl: string;
  OriginalDocumentId: number;
  FileAcceptance: string;
  FileSize: number;
  FileName: string;
  OriginalDocumentVersion: number;
  DecisionAdditionalAttachements: DecisionAdditionalAttachementsType[];
}

interface DecisionsReportsType {
  SectionName: string;
  DecisionAttachements: DecisionAttachementsType;
}

interface SectionAttachmentsType {
  FileContentType: string;
  FileExtension: string;
  FileName: string;
  FileNumber: number;
  FileSize: number;
  FileUrl: string;
  OriginalDocumentId: number;
  TapisId: number;
}

interface AttachmentListType {
  [key: string]: {
    SectionName: string;
    SectionPosition: number;
    SectionAttachments: SectionAttachmentsType[];
  };
}

export const DetailedPlannedDocument = ({
  id,
  isHovered,
  documentTitle,
  documentMunicipality,
  isZoomToPlannedDocument,
}: DetailedPlannedDocumentProps) => {
  const VISIBLE_DECISION_COUNT = 4;

  const [isOpenDecision, setIsOpenDecision] = useState<boolean>(false);
  const [decisionVisibility, setDecisionsVisibility] = useState<number>(VISIBLE_DECISION_COUNT);
  const [attachmentList, setAttachmentList] = useState<TreeProps['treeData']>();
  const [groupedDecisionList, setGroupedDecisionList] = useState<DecisionsReportsType[]>([]);
  const [publicDiscussionList, setPublicDiscussionsList] = useState<TreeProps['treeData']>();
  const [versionList, setVersionList] = useState<any>([]);
  const [showUnauthenticated, setShowUnauthenticated] = useState<boolean>(false);
  const intl = useIntl();
  const { toggleHandler } = useContext(ProposalSubmitContext);
  const dispatch = useOpenedTypeDispatch();
  const map = useContext(MapContext);
  const navigate = useNavigate();
  const { setSessionValue: setSessionProposalValue } = usePlannedDocumentProposal();
  const { isTokenActive } = useJwt();
  const [selectedVersion, setSelectedVersion] = useState<string>();
  const user = useUserState();
  const { state: locationState } = useLocation();

  const visibilityHandler = (arrLength: number, count: number) => {
    setIsOpenDecision((prev) => !prev);

    if (!isOpenDecision) {
      setDecisionsVisibility(arrLength);
    } else {
      setDecisionsVisibility(count);
    }
  };

  const proposalSubmit = () => {
    if (!isTokenActive()) {
      setShowUnauthenticated(true);
      return;
    }

    toggleHandler(data.Label, '24.12.2024 - 25.12.2024', data.PublicDiscussionList[0]['TapisId']);
  };

  const activeRole = user.roles.find((e) => e.id === user.selectedRole);
  const userCanAccess = activeRole?.code === 'authenticated';

  const { data, refetch, isLoading } = useQueryApiClient({
    request: {
      url: `api/v1/tapis/planned-documents/${id}`,
      disableOnMount: true,
    },
    onSuccess: (response) => {
      if (isHovered) {
        return;
      }
      setVersionList(response?.TAPDPreviousVersions || []);
      setSelectedVersion(response.TAPDVersion);
      setPublicDiscussionsList(parsedPublicListForTree(response.PublicDiscussionList || []));
      setAttachmentList(parseAttachmentList(response.TAPDAttachmentList));
      setGroupedDecisionList(parseDecisionList(response.TAPDDecisionAndMaterialList));

      dispatch({
        type: 'SELECT_TAPIS_DOCUMENT',
        payload: {
          selectedTapisDocument: {
            ...response,
            dok_id: id,
            layerGroup: new LayerGroup({
              properties: {
                id: 'tapis_indiv_layers',
                title: 'Plānošanas dokumenta slāņi',
              },
            }),
          },
        },
      });
    },
  });

  const { data: mapFeature, refetch: refetchMapFeature } = useQueryApiClient({
    request: {
      url: `api/v1/tapis/geoserver/document_layers/wfs?service=WFS&request=GetFeature&version=2.0.0&typename=planojuma_robeza&outputFormat=application/json&VIEWPARAMS=dok_id:${id}&count=1`,
      disableOnMount: true,
    },
  });

  useEffect(() => {
    documentTitle(data.Label);
    documentMunicipality(data.TAPDOrganisation);
  }, [data]);

  useEffect(() => {
    refetch();
    refetchMapFeature();
    setIsOpenDecision(false);
    setDecisionsVisibility(VISIBLE_DECISION_COUNT);
  }, [id]);

  const changeSectionNames = (data: AttachmentListType) => {
    const sectionNameMap = { SEC_CITA: 'Citi materiāli' };
    let result = { ...data };

    Object.entries(sectionNameMap).forEach(([section, SectionName]) => {
      if (!(section in data)) {
        return;
      }

      result[section] = {
        ...data[section],
        SectionName,
      };
    });

    return result;
  };

  const parseAttachmentList = (data: AttachmentListType) => {
    if (data) {
      const sections = Object.entries(changeSectionNames(data)).sort(
        ([_, a], [__, b]) => parseInt(String(a.SectionPosition), 10) - parseInt(String(b.SectionPosition), 10)
      );

      return sections.map(([field, entry]: [string, AttachmentListType[keyof AttachmentListType]], index) => {
        const specificCaseRule =
          ['SEC_CITA', 'SEC_ACT_INVEST_CANCEL'].includes(field) && entry.SectionAttachments.length > 0;

        if (entry.SectionAttachments.length > 1 || specificCaseRule) {
          return {
            key: index,
            selectable: false,
            title: entry.SectionName,
            children: [
              {
                key: `event_${Math.random()}`,
                selectable: false,
                title: (
                  <Flex vertical gap={10} className="event-content-wrapper">
                    {entry.SectionAttachments.map((event: SectionAttachmentsType) => {
                      return (
                        <Popover
                          trigger="hover"
                          content={
                            <PopoverWrapper>
                              <Icon faBase="far" icon="file" />
                              {` (${(event.FileSize / 1024 / 1024).toFixed(1)} MB)`}
                              <br />
                              {event?.FileName}
                            </PopoverWrapper>
                          }
                        >
                          <CustomStyledChildrenLinkWrapper>
                            <a className="styled-link" download href={event.FileUrl}>
                              {event.FileName}
                            </a>
                          </CustomStyledChildrenLinkWrapper>
                        </Popover>
                      );
                    })}
                  </Flex>
                ),
              },
            ],
          };
        } else {
          return {
            key: index,
            selectable: false,
            title: (
              <Popover
                trigger="hover"
                content={
                  <PopoverWrapper>
                    <Icon faBase="far" icon="file" />
                    {` (${convertBytesToMegabytes(entry.SectionAttachments[0].FileSize)} MB)`}
                    <br />
                    {entry?.SectionAttachments[0].FileName}
                  </PopoverWrapper>
                }
              >
                <CustomStyledLink download target="_blank" href={entry.SectionAttachments[0].FileUrl}>
                  {entry.SectionName}
                </CustomStyledLink>
              </Popover>
            ),
            children: [],
          };
        }
      });
    }
  };

  const fileLinks = (files: any[]) => {
    const fileLinks = files?.map((file) => (
      <a className="file-link" key={file.FileName} href={file.FileUrl} download={file.FileName}>
        {file.FileName}
      </a>
    ));

    const joinedLinks = fileLinks?.map((link, index) => (
      <>
        {index > 0 && ', '} {link}
      </>
    ));

    return <div>{joinedLinks}</div>;
  };

  const parsedPublicListForTree = (list: any) => {
    const events: TreeProps['treeData'] = [];
    const informationDropdown = {
      key: 2,
      selectable: false,
      title: <div className="more-info-title-text">{intl.formatMessage({ id: 'planned_documents.more_info' })}</div>,
      className: 'mt-2',
      children: [] as Array<{ key: string; selectable: boolean; title: JSX.Element }>,
    };

    list.forEach((entry: any) => {
      entry.PublicDiscussionEvents.map((event: any, index: number) => {
        events.push({
          key: `${list.OriginalDocumentId}_${index}_${Math.random()}`,
          selectable: false,
          title: (
            <div className="title-text">
              {event.PublicEventType} ({dayjs(event.PublicEventDateTime).format('DD.MM.YYYY. HH:mm')})
            </div>
          ),
          children: [
            {
              key: `event_${event.PublicEventDateTime}_${Math.random()}`,
              selectable: false,
              title: (
                <div className="event-content-wrapper">
                  <div className="small-text-wrapper">
                    <div className="small-text-wrapper-title">
                      {intl.formatMessage({ id: 'planned_documents.public_event.place' })}:
                    </div>
                    {event.PublicEventPlace}
                  </div>
                  <div className="small-text-wrapper">
                    <div className="small-text-wrapper-title">
                      {intl.formatMessage({ id: 'planned_documents.public_event.time' })}:
                    </div>
                    {dayjs(event.PublicEventDateTime).format(`DD.MM.YYYY. `)}
                    {intl.formatMessage(
                      {
                        id: 'planned_documents.public_discussion_time_prefix',
                      },
                      { value: dayjs(event.PublicEventDateTime).format(`HH:mm`) }
                    )}
                  </div>
                  <div className="small-text-wrapper">
                    <div className="small-text-wrapper-title">
                      {intl.formatMessage({ id: 'planned_documents.public_event.contact_info' })}:
                    </div>
                    {event.PublicEventContactInfo}
                  </div>
                  {!!event.PublicEventMaterialsFiles && event.PublicEventMaterialsFiles?.length > 0 && (
                    <div className="small-text-wrapper">
                      <div className="small-text-wrapper-title">
                        {intl.formatMessage({ id: 'planned_documents.meeting_protocol' })}:
                      </div>
                      {fileLinks(event.PublicEventMaterialsFiles)}
                    </div>
                  )}
                </div>
              ),
            },
          ],
        });
      });

      if (
        !!entry.PublicDiscussionReception ||
        !!entry.PublicDiscussionMaterials ||
        !!entry.PublicDiscussionContacts ||
        (!!entry.PublicDiscussionMaterialsFiles && entry.PublicDiscussionMaterialsFiles?.length > 0)
      ) {
        informationDropdown.children.push({
          key: 'more_info_button',
          selectable: false,
          title: (
            <div className="event-content-wrapper">
              <div className="small-text-wrapper">
                <div className="small-text-wrapper-title">
                  {intl.formatMessage({ id: 'planned_documents.public_discussion_reception' })}:
                </div>
                {!!entry.PublicDiscussionReception ? (
                  <div className="reception-wrapper">{entry.PublicDiscussionReception}</div>
                ) : (
                  '-'
                )}
              </div>
              <div className="small-text-wrapper">
                <div className="small-text-wrapper-title">
                  {intl.formatMessage({ id: 'planned_documents.public_discussion_materials' })}:
                </div>
                {!!entry.PublicDiscussionMaterials ? entry.PublicDiscussionMaterials : '-'}
              </div>
              <div className="small-text-wrapper">
                <div className="small-text-wrapper-title">
                  {intl.formatMessage({ id: 'planned_documents.public_discussion_contacts' })}:
                </div>
                {!!entry.PublicDiscussionContacts ? entry.PublicDiscussionContacts : '-'}
              </div>
              <div className="small-text-wrapper">
                <div className="small-text-wrapper-title">
                  {intl.formatMessage({ id: 'planned_documents.other_info_materials' })}:
                </div>
                {!!entry.PublicDiscussionMaterialsFiles && entry.PublicDiscussionMaterialsFiles?.length > 0
                  ? fileLinks(entry.PublicDiscussionMaterialsFiles)
                  : '-'}
              </div>
            </div>
          ),
        });
      }
    });

    if (informationDropdown.children.length > 0) {
      return [...events, informationDropdown];
    } else {
      return events;
    }
  };

  const renderDecisionTreeItem = (decision: DecisionAttachementsType | DecisionAdditionalAttachementsType) => (
    <Popover
      content={
        <PopoverWrapper>
          {decision?.FileSize ? (
            <>
              {decision?.FileName}
              <br />
              <StyledFileVersionWrapper>
                <Icon faBase="far" icon="file" />
                <FormattedMessage
                  id="planned_documents.tooltip"
                  values={{
                    version: decision?.OriginalDocumentVersion,
                    fileSize: convertBytesToMegabytes(decision?.FileSize),
                  }}
                />
              </StyledFileVersionWrapper>
            </>
          ) : (
            <>
              <Icon faBase="fas" icon="link" />
              {` ${decision?.FileUrl}`}
            </>
          )}
        </PopoverWrapper>
      }
      trigger="hover"
    >
      <CustomStyledLink target="_blank" href={decision?.FileUrl} download>
        {decision?.FileName}
      </CustomStyledLink>
    </Popover>
  );

  const parseDecisionList = (list: { [key in string]: DecisionsReportsType }): DecisionsReportsType[] => {
    return Object.values<DecisionsReportsType>(list).sort((a, b) => {
      const dateA = dayjs(a.DecisionAttachements?.FileAcceptance);
      const dateB = dayjs(b.DecisionAttachements?.FileAcceptance);

      switch (true) {
        case !dateA.isValid():
          return 1;

        case !dateB.isValid():
          return -1;

        default:
          return dateA.isValid() && dateA.isBefore(dateB) ? 1 : -1;
      }
    });
  };

  const getDecisionTreeNodes = (decisions: DecisionsReportsType) => {
    const firstDecision = decisions.DecisionAttachements;

    if (!firstDecision) {
      return [];
    }

    const childDecisions = firstDecision.DecisionAdditionalAttachements || [];

    if (childDecisions.length) {
      return [
        {
          key: -1,
          selectable: false,
          title: renderDecisionTreeItem(firstDecision),
          children: [
            {
              key: `event_${Math.random()}`,
              selectable: false,
              title: (
                <Flex vertical gap={10} className="event-content-wrapper">
                  {childDecisions.map((decision: DecisionAdditionalAttachementsType) =>
                    renderDecisionTreeItem(decision)
                  )}
                </Flex>
              ),
            },
          ],
        },
      ];
    }

    return [
      {
        key: -1,
        selectable: false,
        title: renderDecisionTreeItem(firstDecision),
        children: [],
      },
    ];
  };

  const openInMap = () => {
    if (map && mapFeature) {
      highlightFeaturesGeoJson(map, mapFeature);
    }
  };

  const copySite = async () => {
    try {
      await navigator.clipboard.writeText(`${window?.runConfig?.frontendUrl}/geo/tapis?#document_${id}`);
      toastMessage.success(intl.formatMessage({ id: 'planned_documents.copied_successfully' }));
    } catch (e) {
      toastMessage.error(intl.formatMessage({ id: 'planned_documents.copied_error' }));
      console.error('Failed to copy Document URL:', e);
    }
  };

  const getDiscussionText = (discussion: any) => {
    const from = dayjs(discussion.PublicDiscussionDateFrom);
    const to = dayjs(discussion.PublicDiscussionDateTo);
    const today = dayjs().endOf('day');

    // Calculate difference including today
    const diff = to.endOf('day').diff(today, 'day') + 1;

    return (
      from.format('DD.MM.YYYY.') +
      ' - ' +
      to.format('DD.MM.YYYY.') +
      ' ' +
      (diff > 0 && discussion ? '(atlikušas ' + diff + ' dienas)' : '')
    );
  };

  const computedStatus = data?.TAPDStatuss
    ? listStatuses.find((entry) => entry.treeJsonTitle === data?.TAPDStatuss)
    : undefined;

  const onUnauthenticated = () => {
    setSessionProposalValue({
      id,
      open: true,
    });
  };

  const onVersionChange = (version: any) => {
    const { action: previousAction } = locationState;
    const state = {
      action:
        previousAction === navigationAction.planned_doc_map_select
          ? navigationAction.planned_doc_map_select
          : navigationAction.planned_doc_version_change,
    };

    navigate(`/geo/tapis#document_${version.DocumentId}#nozoom`, { state });
    setSelectedVersion(version.Version);
  };

  const replaceUrlWithTag = (text?: string, isImportant = false): ReactNode => {
    const urlRegex = /(https?:\/\/[^\s)]+(?:\([^)]*\))*)/g;
    return text?.split(urlRegex).map((part, index) => {
      if (index % 2 === 1) {
        return (
          <StyledHref isImportant={isImportant} key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </StyledHref>
        );
      }
      return part;
    });
  };

  const renderActions = () => {
    return (
      <StyledActions className="actions-wrapper">
        <Flex justify="space-between" align="center" className="actions">
          <Flex gap={8} align="center" className="status-version">
            <Tag label={computedStatus?.title || ''} color={computedStatus?.color || ''} />
            {versionList.length > 0 ? (
              <Popover
                className="version-popover"
                placement="bottom"
                content={
                  <StyledPopover className="versions-list detailed-planned-documents">
                    {versionList.map((e: any) => (
                      <div className="entry" onClick={() => onVersionChange(e)}>
                        <FormattedMessage id="planned_documents.editorial" values={{ version: e.Version }} />
                      </div>
                    ))}
                  </StyledPopover>
                }
                trigger="hover"
              >
                <b>{selectedVersion}</b>
                {versionList.length > 0 && <Icon className="ml-1 mb-0_5" icon="caret-down" />}
              </Popover>
            ) : (
              <b>{selectedVersion}</b>
            )}
          </Flex>
          <Flex className="icons">
            <Tooltip hack title={intl.formatMessage({ id: 'tooltip.open_in_map' })}>
              <Icon faBase="fal" icon="lightbulb" onClick={openInMap} />
            </Tooltip>
            <Tooltip hack title={intl.formatMessage({ id: 'tooltip.copy' })}>
              <Icon faBase="fal" icon="share" onClick={copySite} />
            </Tooltip>
          </Flex>
        </Flex>
      </StyledActions>
    );
  };

  const renderPublicParticipationList = (participationTreeList: any[]) => {
    const participation = data?.PublicDiscussionList[0];

    return (
      <Flex className="public-particiaption-list">
        <Flex vertical gap={20}>
          <StyledCard key={`event_${participation.OriginalDocumentId}_list`} className="public-particiaption">
            <StyledTreeItemsWrapper>
              <Label
                className="mb-3 section-title"
                label={intl.formatMessage({ id: 'planned_documents.public_participation' })}
              />
              <div className="date">
                <div>
                  {computedStatus?.searchStatus === 'pending_approval'
                    ? intl.formatMessage({ id: 'planned_documents.public_consultation_period' })
                    : intl.formatMessage({ id: 'planned_documents.public_consultation_took_place' })}
                </div>
                <div>{getDiscussionText(participation)}</div>
              </div>
              <div className="tree-wrapper">
                <DirectoryTree selectable={false} showIcon={false} treeData={participationTreeList} />
              </div>
            </StyledTreeItemsWrapper>
          </StyledCard>
        </Flex>
      </Flex>
    );
  };

  const renderSubmitProposal = () => {
    return (
      <div className="submit-proposal-wrapper">
        {!userCanAccess && isTokenActive() ? (
          <TooltipWrapper>
            <Tooltip title={intl.formatMessage({ id: 'planned_documents.submit_proposal_not_allowed' })}>
              <span>
                <Button
                  disabled={!userCanAccess && isTokenActive()}
                  type="primary"
                  className="full"
                  onClick={proposalSubmit}
                  label={intl.formatMessage({ id: 'planned_documents.submit_proposal' })}
                />
              </span>
            </Tooltip>
          </TooltipWrapper>
        ) : (
          <span>
            {!activeRole?.emailVerified && isTokenActive() ? (
              <TooltipWrapper>
                <span>
                  <Button
                    disabled={!userCanAccess}
                    type="primary"
                    className="full"
                    onClick={proposalSubmit}
                    label={intl.formatMessage({ id: 'planned_documents.submit_proposal' })}
                  />
                </span>
              </TooltipWrapper>
            ) : (
              <Button
                type="primary"
                className="full"
                onClick={proposalSubmit}
                label={intl.formatMessage({ id: 'planned_documents.submit_proposal' })}
              />
            )}
          </span>
        )}
      </div>
    );
  };

  const renderDecisions = () => {
    if (!groupedDecisionList) {
      return null;
    }

    const visibleGroups = groupedDecisionList.slice(0, decisionVisibility);

    return (
      <Section className="decision-section">
        <Label
          className="mb-3 section-title"
          label={intl.formatMessage({ id: 'planned_documents.decisions_reports' })}
        />
        <Flex vertical gap={10} className="tree-group-wrapper">
          {visibleGroups.map((decisions) => (
            <Flex vertical gap={4} className="tree-group">
              {dayjs(decisions.DecisionAttachements.FileAcceptance).isValid() && (
                <Label
                  label={dayjs(decisions.DecisionAttachements.FileAcceptance).format('DD.MM.YYYY')}
                  className="date"
                />
              )}
              <TreeWrapper className="tree">
                <DirectoryTree treeData={getDecisionTreeNodes(decisions)} selectable={false} showIcon={false} />
              </TreeWrapper>
            </Flex>
          ))}
          {groupedDecisionList.length > VISIBLE_DECISION_COUNT && (
            <Flex className="show-more-btn">
              <Button
                type="text"
                icon="arrow-right-long"
                onClick={() => visibilityHandler(groupedDecisionList.length, VISIBLE_DECISION_COUNT)}
                label={
                  <div className="ml-1_5">
                    {intl.formatMessage({
                      id: !isOpenDecision ? 'planned_documents.show_more' : 'planned_documents.show_less',
                    })}
                  </div>
                }
              />
            </Flex>
          )}
        </Flex>
      </Section>
    );
  };

  const renderLayersSection = () => {
    return (
      <Section className="layers-section">
        <Label className="mb-3 section-title" label={intl.formatMessage({ id: 'planned_documents.layers' })} />
        <PlannedDocumentLayerSettings zoom={isZoomToPlannedDocument} />
      </Section>
    );
  };

  const renderParticipationSection = () => {
    const displayAppealDeadline =
      !!data.DisputeDate && dayjs(data.DisputeDate).endOf('day').diff(dayjs().endOf('day'), 'days') + 1 > 0;
    const isPublicDiscussionEmpty = !publicDiscussionList?.length;
    const canSubmit = !isPublicDiscussionEmpty && computedStatus?.searchStatus === 'pending_approval';

    if (isPublicDiscussionEmpty) {
      return null;
    }

    return (
      <Section className="participation-section">
        <div>
          <ExplanationWrapper className="mb-3" style={data?.ExplanationIsImportant ? { color: '#b2291f' } : undefined}>
            {replaceUrlWithTag(data?.ExplanationNote, data?.ExplanationIsImportant)}
          </ExplanationWrapper>
        </div>
        {displayAppealDeadline && (
          <div className="small-text-wrapper-title my-4">
            {intl.formatMessage({ id: 'planned_documents.appeal_deadline' }) +
              ' ' +
              dayjs(data.DisputeDate).format('DD.MM.YYYY') +
              intl.formatMessage(
                { id: 'planned_documents.appeal_rest' },
                { days: dayjs(data.DisputeDate).endOf('day').diff(dayjs().endOf('day'), 'days') + 1 }
              )}
          </div>
        )}
        {renderPublicParticipationList(publicDiscussionList)}
        {canSubmit && renderSubmitProposal()}
        <UnauthenticatedModal
          additionalOnOkExecution={onUnauthenticated}
          setShowModal={setShowUnauthenticated}
          showModal={showUnauthenticated}
        />
      </Section>
    );
  };

  return (
    <>
      <Spinner spinning={isLoading}>
        {renderActions()}
        {renderParticipationSection()}
        {attachmentList && (
          <Section className="attachment-section">
            <Label className="mb-3 section-title" label={intl.formatMessage({ id: 'planned_documents.sections' })} />
            <TreeWrapper>
              <DirectoryTree treeData={attachmentList} selectable={false} showIcon={false} />
            </TreeWrapper>
          </Section>
        )}
        {renderDecisions()}
        {renderLayersSection()}
      </Spinner>
    </>
  );
};
