import React, { createContext, ReactNode, type SetStateAction, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Form } from 'antd';
import { FormInstance } from 'antd/es/form/hooks/useForm';

export const GeoProductsFilterContext = createContext({} as GeoProductsFilterContextProps);

interface GeoProductsFilterContextProps {
  filterParams: FilterParamsType;
  setFilters: (filters: Filter) => void;
  setSearchValues: (searchValues: SearchOptions) => void;
  form: FormInstance<FilterForm>;
  resetFilterInputs: Function;
}

interface Filter {
  organization?: number[];
  inspire?: boolean;
}

interface SearchOptions {
  search?: string;
}

interface FilterParamsType {
  recaptchaKey?: string | null;
  filter: Filter;
  searchOptions: SearchOptions;
}

interface FilterForm extends Filter, SearchOptions {}

export const useGeoProductsFilterContext = () => {
  return useContext(GeoProductsFilterContext);
};

const GeoProductsFilterContextProvider = ({ children }: { children: ReactNode }) => {
  let [searchParams] = useSearchParams();

  const initialState = {
    filter: { organization: [], inspire: false },
    searchOptions: { search: '' },
  };
  const storageKey = 'geoProductsFilterValues';
  const searchString = searchParams.get('search');
  const storedDataString = sessionStorage.getItem(storageKey);
  const storedData: FilterParamsType = storedDataString ? JSON.parse(storedDataString) : initialState;
  const [filterParams, setFilterParams] = useState<FilterParamsType>(
    !!searchString ? { ...storedData, searchOptions: { search: searchString } } : storedData
  );

  const [form] = Form.useForm<FilterForm>();

  useEffect(() => {
    if (storedData) {
      if (!form.isFieldTouched('search')) {
        form.setFieldValue('search', storedData.searchOptions.search || '');
      }

      if (!form.isFieldTouched('organization')) {
        form.setFieldValue('organization', storedData.filter.organization || []);
      }
    }
  }, [storedData, form]);

  useEffect(() => {
    if (!!searchString) {
      form.setFieldValue('search', searchString);
    }

    const resetSessionStorage = () => sessionStorage.removeItem(storageKey);

    window.addEventListener('beforeunload', resetSessionStorage);

    return () => {
      window.removeEventListener('beforeunload', resetSessionStorage);
    };
  }, [form, searchString]);

  const setFilterValues = (filters: SetStateAction<FilterParamsType>) => {
    sessionStorage.setItem(storageKey, JSON.stringify(filters));
    setFilterParams(filters);
  };

  const setFilters = (filters: Filter) => {
    const { filter: existingValues, ...rest } = filterParams;

    setFilterValues({
      ...rest,
      filter: {
        ...existingValues,
        ...filters,
      },
    });
  };

  const setSearchValues = (searchValues: SearchOptions) => {
    const { searchOptions: existingValues, ...rest } = filterParams;

    setFilterValues({
      ...rest,
      searchOptions: {
        ...existingValues,
        ...searchValues,
      },
    });
  };

  const resetFilterInputs = () => {
    form.setFieldValue('search', undefined);
    form.setFieldValue('organization', undefined);
  };

  return (
    <GeoProductsFilterContext.Provider
      value={{
        filterParams,
        setFilters,
        setSearchValues,
        form,
        resetFilterInputs,
      }}
    >
      {children}
    </GeoProductsFilterContext.Provider>
  );
};

export default GeoProductsFilterContextProvider;
