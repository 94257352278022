import React, { useState } from 'react';
import DefaultLayout from 'components/DefaultLayout';
import { Button, DatePicker, Input, Select, SelectOption, Spinner } from 'ui';
import { Col, Form, Row } from 'antd';
import { useIntl } from 'react-intl';
import { ButtonList, StyledForm, StyledPage } from 'styles/layout/form';
import { pages } from 'constants/pages';
import { QuillEditor } from 'ui/quillEditor';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import useQueryApiClient from 'utils/useQueryApiClient';

export type EmailNotificationStatus = 'DRAFT' | 'PLANNED' | 'SENT';

export interface EmailNotification {
  id: number;
  subject: string;
  content: string;
  status: EmailNotificationStatus;
  updatedAt: string;
  scheduledAt: string;
  sentAt: string;
  roleIds: number[];
}

const BaseEditPage = () => {
  const [recipients, setRecipients] = useState([]);
  const [formState, setFormState] = useState<boolean>(false);

  const intl = useIntl();
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const { isLoading: isInitialLoading } = useQueryApiClient({
    request: {
      url: `api/v1/email-notifications/${id}`,
      disableOnMount: !id,
    },
    onSuccess: ({ scheduledAt, sentAt, ...rest }: EmailNotification) => {
      form.setFieldsValue({
        ...rest,
        scheduledAt: scheduledAt ? dayjs(scheduledAt) : '',
        sentAt: sentAt ? dayjs(sentAt) : '',
      });
      setFormState(true);
    },
  });

  const leavePage = () => {
    navigate(pages.emailNotification.url);
  };

  const { isLoading: isRecipientsLoading } = useQueryApiClient({
    request: { url: 'api/v1/email-notifications/recipients' },
    onSuccess: setRecipients,
  });

  const { appendData: saveData, isLoading: isSaveLoading } = useQueryApiClient({
    request: {
      url: id ? `api/v1/email-notifications/${id}` : `api/v1/email-notifications`,
      method: id ? 'PATCH' : 'POST',
    },
    onSuccess: leavePage,
  });

  const onSend = async () => {
    form.validateFields().then((values: any) =>
      saveData({
        ...values,
        status: 'PLANNED',
      })
    );
  };

  const onSave = () => {
    saveData({
      ...form.getFieldsValue(),
      status: 'DRAFT',
    });
  };

  return (
    <Spinner spinning={isInitialLoading || isRecipientsLoading || isSaveLoading}>
      <DefaultLayout.PageLayout>
        <DefaultLayout.PageContent>
          <StyledPage>
            <DefaultLayout.PageHeader
              breadcrumb={[
                {
                  path: '/',
                  name: intl.formatMessage({ id: 'general.administration' }),
                },
                {
                  path: pages.emailNotification.url,
                  name: intl.formatMessage({ id: pages.emailNotification.title }),
                },
              ]}
              title={intl.formatMessage({
                id: id ? pages.emailNotification.edit.title : pages.emailNotification.create.title,
              })}
            />
            <StyledForm>
              <Form form={form} layout="vertical">
                <Select
                  mode="multiple"
                  label={intl.formatMessage({ id: 'email_notification.recipient_list' })}
                  name="recipients"
                  validations={['required']}
                >
                  {recipients.map((entry: any) => (
                    <SelectOption key={entry.value} value={entry.value}>
                      {entry.label}
                    </SelectOption>
                  ))}
                </Select>
                <Input
                  name="subject"
                  label={intl.formatMessage({ id: 'email_notification.subject' })}
                  validations={['required']}
                  maxLength={100}
                />
                <QuillEditor
                  maxLength={600}
                  name="content"
                  label={
                    <label className="fake-required">{intl.formatMessage({ id: 'email_notification.content' })}</label>
                  }
                  validations={['requiredRichText']}
                  enableLinks={true}
                  formSet={formState}
                />
                <Row gutter={20}>
                  <Col flex="240px">
                    <DatePicker
                      name="scheduledAt"
                      label={intl.formatMessage({ id: 'email_notification.sent_at_time' })}
                      format="DD.MM.YYYY HH:mm"
                      showTime
                    />
                  </Col>
                </Row>
                <Row justify="end">
                  <ButtonList>
                    <Button label={intl.formatMessage({ id: 'general.save' })} onClick={onSave} />
                    <Button
                      label={intl.formatMessage({ id: 'email_notification.send_v2' })}
                      type="primary"
                      onClick={onSend}
                    />
                  </ButtonList>
                </Row>
              </Form>
            </StyledForm>
          </StyledPage>
        </DefaultLayout.PageContent>
      </DefaultLayout.PageLayout>
    </Spinner>
  );
};

export default BaseEditPage;
