import { Icon, Input, ItemOptions, Label, List, Select, SelectOption, Tag, Tooltip } from 'ui';
import React, { type MutableRefObject, useEffect, useRef, useState } from 'react';
import { Flex, Form } from 'antd';
import { useIntl } from 'react-intl';
import { listStatuses } from 'config/config';
import { StyledListContainer, StyledSearchContainer } from './styles';
import { useDebounce } from 'utils/useDebounce';
import {
  ACTIVITY_SORT_BY,
  DEFAULT_SORT_BY,
  usePlannedDocumentsFilterContext,
} from 'contexts/PlannedDocumentsFilterContext';
import PlannedDocumentsOrganizationSelect from 'components/Selects/PlannedDocumentsOrganizationSelect';
import { useSearchParams } from 'react-router-dom';

interface PlannedDocumentsProps {
  setSelectedPlannedDocument: (id: number) => void;
  setSelectedTitle?: (title: string) => void;
  isOpenDocument: boolean;
}

const PlannedDocuments = ({ isOpenDocument, setSelectedPlannedDocument, setSelectedTitle }: PlannedDocumentsProps) => {
  const { filterParams, setFilterParams, form } = usePlannedDocumentsFilterContext();
  const debouncedDataFilter = useDebounce(filterParams, 1000);
  const [searchParams] = useSearchParams();
  const searchString = searchParams.get('search');

  const [visible, setVisible] = useState<boolean>(false);
  const [isCompressed, setIsCompressed] = useState<boolean>(!!sessionStorage.getItem('plannedDocuments_isCompressed'));
  const ref = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  window.onbeforeunload = function () {
    sessionStorage.clear();
  };

  const updateCompressedState = () => {
    setIsCompressed((old) => {
      const isCompressed = !old;

      if (isCompressed) {
        sessionStorage.setItem('plannedDocuments_isCompressed', isCompressed.toString());
      } else {
        sessionStorage.removeItem('plannedDocuments_isCompressed');
      }

      return isCompressed;
    });
  };

  // Adds "..." at the end of the title if it exceeds four words.
  const getFormattedTitle = (title: string) => {
    const splitInWords = title.split(' ');
    return splitInWords.length > 4 ? splitInWords.slice(0, 4).join(' ') + '...' : title;
  };

  const handleFilterChange = (value: string | string[] | boolean, key: string) => {
    const updatedFilterParams = {
      ...filterParams,
      bbox: undefined,
      [key]: value,
    };

    setFilterParams(updatedFilterParams);
    sessionStorage.setItem('filterValues', JSON.stringify(updatedFilterParams));
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const isOpen = searchParams.get('documents') === 'open';

    if (isOpen && !!searchString) {
      form.setFieldValue('search', searchString);
      form.submit();
    }
  }, [window.location.search]);

  const renderCompress = () => {
    const tooltipTitleId = isCompressed ? 'planned_documents.decompress_tooltip' : 'planned_documents.compress_tooltip';
    const icon = isCompressed ? 'arrows-from-line' : 'arrows-to-line';

    return (
      <Tooltip title={intl.formatMessage({ id: tooltipTitleId })}>
        <div className="clickable compress-icon" onClick={updateCompressedState}>
          <Icon faBase="fa-regular" icon={icon} />
        </div>
      </Tooltip>
    );
  };

  const renderSortBy = () => {
    const isSortByActivity = debouncedDataFilter?.sortBy === ACTIVITY_SORT_BY;
    const tooltipTitleId = isSortByActivity
      ? 'planned_documents.sort_by_order_tooltip'
      : 'planned_documents.sort_by_activity_tooltip';

    return (
      <Tooltip title={intl.formatMessage({ id: tooltipTitleId })}>
        <div
          className={`clickable ${debouncedDataFilter?.sortBy}`}
          onClick={(e) => {
            handleFilterChange(isSortByActivity ? DEFAULT_SORT_BY : ACTIVITY_SORT_BY, 'sortBy');
          }}
        >
          <Icon faBase="fa-solid" icon="bars-sort" className="sortby-icon" />
        </div>
      </Tooltip>
    );
  };

  const renderItem = (
    _: any,
    { title, status: { color = '', shortTitle, title: statusTitle } = {}, isStatus }: ItemOptions
  ) => {
    const className = `box`;

    if (isCompressed) {
      return (
        <div className={`${className} compact`}>
          <Tooltip title={title}>
            <div className="shortTitle">
              <span>{title}</span>
            </div>
          </Tooltip>
          {isStatus && (
            <Tooltip hack title={statusTitle}>
              <Tag color={color} label={shortTitle} />
            </Tooltip>
          )}
        </div>
      );
    }

    return (
      <div className={className}>
        <Tooltip title={title}>
          <div className="title">
            <span>{title}</span>
          </div>
        </Tooltip>
        {isStatus && <Tag color={color} label={statusTitle} />}
      </div>
    );
  };

  return (
    <>
      <Form form={form} onFinish={({ search = '' }) => handleFilterChange(search, 'search')} className="mb-4">
        <div>
          <Input
            name="search"
            placeholder={intl.formatMessage({ id: 'planned_documents.search_by_name' })}
            onChange={(e) => {
              handleFilterChange(e.target.value, 'search');
            }}
          />
          <StyledSearchContainer>
            <div className={`${!visible && 'hide-container'}`}>
              <PlannedDocumentsOrganizationSelect
                mode="multiple"
                name="organization"
                onChange={(values: string[]) => {
                  handleFilterChange(values, 'organization');
                }}
              />
              <Select
                mode="multiple"
                name="status"
                placeholder={intl.formatMessage({ id: 'planned_documents.search_by_status' })}
                onChange={(values: string[]) => handleFilterChange(values, 'status')}
              >
                {listStatuses.map((entry) => {
                  if (!entry.useInSearch) {
                    return <></>;
                  }

                  return (
                    <SelectOption key={entry.searchStatus} value={entry.searchStatus}>
                      {getFormattedTitle(entry.title)}
                    </SelectOption>
                  );
                })}
              </Select>
            </div>
            <Flex justify="space-between" align="center" className="action-wrapper">
              <Flex onClick={() => setVisible((old) => !old)}>
                {!visible ? (
                  <Flex align="center" gap="small" className="clickable">
                    <Icon icon="arrow-right-long" faBase="far" />{' '}
                    <Label clickable label={intl.formatMessage({ id: 'planned_documents.additional_search' })} />
                  </Flex>
                ) : (
                  <Flex align="center" gap="small">
                    <Icon icon="arrow-right-long" faBase="far" />{' '}
                    <Label clickable label={intl.formatMessage({ id: 'planned_documents.simple_search' })} />
                  </Flex>
                )}
              </Flex>
              {renderCompress()}
              {renderSortBy()}
            </Flex>
          </StyledSearchContainer>
        </div>
      </Form>
      <StyledListContainer className="planned-documents list-wrapper" ref={ref}>
        <List
          className={`planned-documents ${isCompressed ? 'compact' : ''}`}
          hoverItem={true}
          isPlanned={true}
          isOpenDocument={isOpenDocument}
          pageSize={50}
          url="api/v1/tapis/planned-documents"
          onSelect={(id: number) => setSelectedPlannedDocument(id)}
          selectedTitle={(title: string) => setSelectedTitle?.(title)}
          dataKey="features"
          filterParams={debouncedDataFilter}
          renderItem={renderItem}
          useClientHeight={{ enable: true, ref }}
          properties={{
            prefix: 'properties',
            title: 'dok_nosaukums',
            status: 'dok_status',
            id: 'dok_id',
          }}
        />
      </StyledListContainer>
    </>
  );
};

export default PlannedDocuments;
