import styled from 'styled-components/macro';
import { Upload } from 'antd';

export const StyledUpload = styled(Upload)`
  .ant-upload {
    &-disabled button {
      color: ${({ theme }) => theme.disabledText};
      border-color: ${({ theme }) => theme.disabledBorder};
      background-color: ${({ theme }) => theme.gray01};
      cursor: not-allowed;
    }

    &-list {
      &-item {
        max-width: 260px; //todo for now it's enough
        width: min-content;
        margin-bottom: 5px;
        margin-top: 0;

        &-actions {
          display: flex;
        }

        &-name {
          padding: 0;
          pointer-events: none;
          color: ${({ theme }) => theme.brand02};
        }
      }
    }
  }
`;
